<template>
  <div class="standard-login">
    <FormCard />
  </div>
</template>

<script>
import FormCard from "./components/FormCard.vue";

export default {
  name: "StandardLogin",
  components: {
    FormCard,
  },
  created() {
    /* eslint-disable global-require */
    const link = document.querySelector("link[rel~='icon']");
    const logoUrl = require("../../assets/images/letter-p.png");
    link.href = logoUrl;
  },
};
</script>

<style lang="scss" scoped>
.standard-login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/camion-autonomo-de-aurora.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
</style>
